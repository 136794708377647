<template>
  <div class="figure">
    <v-media
      class="figure__media"
      :src="src"
      :srcset="srcset"
      :sizes="sizes"
      :link="link"
      :share="share"
      :media-link="mediaLink"
      :tags="tags"
      :ratio="ratio"
      :poster="poster"
      :lazy="lazy"
    />
    <p class="figure__caption" v-if="caption">{{ caption }}</p>
  </div>
</template>

<script>
import VMedia from './VMedia.vue'

const MEDIA_RATIO = 12 / 18

export default {
  name: 'VFigure',

  components: {
    VMedia
  },

  props: {
    lazy: {
      type: String,
      required: false,
      default: 'lazy'
    },
    src: {
      type: String,
      required: true
    },
    srcset: {
      type: String,
      default: ''
    },
    sizes: {
      type: String,
      default: ''
    },
    caption: {
      type: String,
      default: ''
    },
    poster: {
      type: String,
      default: ''
    },
    ratio: {
      type: Number,
      default: MEDIA_RATIO
    },
    link: {
      type: Object,
      default: null
    },
    share: {
      type: Object,
      default: null
    },
    mediaLink: {
      type: String,
      default: ''
    },
    tags: {
      type: Array,
      default: null
    }
  }
}
</script>

<style lang="sass">
.figure
  margin: var(--gutter) 0

  &__media
    width: calc(50% - var(--gutter) * 1.5)
    margin: 0 auto

  &__caption
    width: 41%
    margin: var(--gutter) auto 0 auto
    white-space: pre-line
    font:
      size: 10px
      weight: bold
    text:
      align: center
      transform: uppercase

  +small
    padding: 0 var(--gutter)

    &__media
      width: 100%
</style>
