export default class VideoInstancesManager {
  static attach(src, context, parameters = {}) {
    let manager = this.managers.get(src)

    if (!manager) {
      manager = new this({ src, ...parameters })
      this.managers.set(src, manager)
    }

    manager.attach(context)
    return manager
  }

  static detach(src, context) {
    const manager = this.managers.get(src)

    if (!manager) {
      manager.detach(context)
      if (!manager.instances.length) {
        manager.destroy()
        this.managers.delete(src)
      }
    }
  }

  constructor({
    src,
    type = '',
    muted = false,
    autoplay = false,
    playsinline = false,
    loop = false
  }) {
    this.tick = this.tick.bind(this)
    this.draw = this.draw.bind(this)
    this.initialize = this.initialize.bind(this)

    this.frameRequest = null
    this.running = false
    this.pending = false
    this.instances = []

    this.video = document.createElement('video')
    this.video.addEventListener('loadedmetadata', () => {
      this.instances.forEach(this.initialize)
      if (this.pending) {
        this.pending = false
        this.start()
      }
    })

    this.video.src = src
    this.video.muted = muted
    this.video.type = type
    this.video.autoplay = autoplay
    this.video.playsinline = playsinline
    this.video.loop = loop

    this.video.style.position = 'fixed'
    this.video.style.pointerEvents = 'none'
    this.video.style.top = '0'
    this.video.style.left = '0px'
    this.video.style.zIndex = '-10000000'
    this.video.style.opacity = '0.000001'
    this.video.style.width =
    this.video.style.height = '1px'

    document.body.appendChild(this.video)
  }

  attach(context) {
    const instance = { context, running: false }
    this.initialize(instance)
    this.instances.push(instance)
  }

  detach(context) {
    this.disable(context)
    const index = this.instances.findIndex(instance => instance.context === context)
    index === -1 || this.instances.splice(index, 1)
  }

  enable(context) {
    const instance = this.instances.find(instance => instance.context === context)

    if (instance) {
      instance.running = true
      this.start()
    }
  }

  disable(context) {
    let instance

    const running = this.instances.reduce((running, instance) => {
      if (instance.context === context) {
        instance.running = false
      }

      return running || instance.running
    }, false)

    running || this.stop()
  }

  destroy() {
    this.running && this.stop()
    this.instances.splice(0)
    this.video.parentNode && this.video.parentNode.removeChild(this.video)
  }

  start() {
    if (this.video.readyState === HTMLMediaElement.HAVE_NOTHING) {
      this.pending = true
      return
    }

    if (!this.running) {
      this.running = true
      this.tick()
    }
  }

  stop() {
    this.pending = false

    if (this.running) {
      this.running = false
      cancelAnimationFrame(this.frameRequest)
    }
  }

  tick() {
    this.instances.reduce(this.draw, null)
    this.frameRequest = requestAnimationFrame(this.tick)
  }

  draw(imageData, instance) {
    if (instance.running) {
      if (imageData) {
        instance.context.putImageData(imageData, 0, 0)
      } else {
        instance.context.drawImage(this.video, 0, 0)
        imageData = instance.context.getImageData(
          0,
          0,
          this.video.videoWidth,
          this.video.videoHeight
        )
      }
    }

    return imageData
  }

  initialize(instance) {
    if (this.video.readyState === HTMLMediaElement.HAVE_NOTHING) {
      return
    }

    instance.context.canvas.width = this.video.videoWidth
    instance.context.canvas.height = this.video.videoHeight
  }
}

VideoInstancesManager.managers = new Map()
