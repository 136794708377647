<template>
    <div class="title">
      <h1>Saint Laurent</h1>
      <v-media
        class="cover__media"
        :src="src"
        :ratio="ratio"
      />
    </div>
</template>

<script>
import VMedia from './VMedia.vue'
export default {
  name: 'VTitle',
  components: {
    VMedia
  },
  props: {
    ratio: {
      type: Number,
      default: 0
    },
    src: {
      type: String,
      required: true
    },
  }
}
</script>

<style scoped lang="sass">
h1
  visibility: hidden
  transform: translate(-9999px, -9999px)
.title 
    box-sizing: border-box
    width: calc(100% - var(--guter)*2)
    margin: var(--gutter) auto
    margin-bottom: 0

    img 
        width: 100%
</style>
