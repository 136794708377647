<template>
  <p
    class="caption"
    :class="{ 'caption--big': big }"
  >
    <span><slot /></span>
  </p>
</template>

<script>
export default {
  name: 'VCaption',

  props: {
    big: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass">
.caption
  width: 100%
  max-width: calc(360px + var(--gutter) * 2)
  margin: var(--innerheightgutter) auto
  padding: 0 var(--gutter)
  box-sizing: border-box
  line-height: 90%
  letter-spacing: -0.04em
  text:
    align: center
    transform: uppercase
  font-size: 10px
  font-weight: bold

  display: flex
  flex-direction: row
  align-items: center
  justify-content: center

  &--big
    font:
      weight: bold
      size: 24px
    letter-spacing: -0.03em
</style>
