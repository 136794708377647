<template>
  <intersect @enter="enable" @leave="disable">
    <canvas moz-opaque />
  </intersect>
</template>

<script>
import Intersect from 'vue-intersect'
import VideoInstancesManager from '../javascripts/VideoInstancesManager.js'

export default {
  name: 'VVideoInstance',

  components: {
    Intersect
  },

  props: {
    src: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: ''
    },
    loop: {
      type: Boolean,
      default: false
    },
    muted: {
      type: Boolean,
      default: false
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    playsinline: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    src(newSrc, oldSrc) {
      oldSrc && this.detach(oldSrc)
      this.attach(newSrc)
    },

    muted(muted) {
      this.manager.video.muted = muted
    }
  },

  created() {
    this.volumechange = this.volumechange.bind(this)
    this.manager = false
    this.context = null
  },

  mounted() {
    this.context = this.$el.getContext('2d')
    this.attach(this.src)
  },

  beforeDestroy() {
    VideoInstancesManager.detach(this.src, this.context)
  },

  methods: {
    play() {
      this.manager && this.manager.video.play()
    },

    detach(src) {
      if (this.manager) {
        this.manager.video.removeEventListener('volumechange', this.volumechange)
      }

      VideoInstancesManager.detach(oldSrc, this.context)
    },

    attach(src) {
      this.manager = VideoInstancesManager.attach(src, this.context, {
        type: this.mimeType,
        autoplay: this.autoplay,
        playsinline: this.playsinline,
        loop: this.loop,
        muted: this.muted
      })

      this.manager.video.addEventListener('volumechange', this.volumechange)
    },

    enable() {
      this.manager && this.manager.enable(this.context)
    },

    disable() {
      this.manager && this.manager.disable(this.context)
    },

    volumechange(event) {
      this.$emit('volumechange', event)
    }
  }
}
</script>
